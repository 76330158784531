import Quill from 'quill';
import { fromEvent } from 'rxjs';
import { filter, map, pluck, tap, } from 'rxjs/operators';

// Open presentation:
// get html
// loop through children and create slides
// add html to presentation
// enter full screen
// listeners for changing slides

// Close presentation:
// close full screen

// Close full screen listener:
// empty presentation
// destroy listeners for changing slides

// Presentation listeners:
// up (prev)
// down
// space
// click

// Slides:
// Keep current index
// Get positions
// Slide animation
// Next
// Previous

const presentation = document.getElementById('presentation');
const presentationContent = document.querySelector('.presentation__content');
const openButton = document.getElementById('open').addEventListener('click', () => openPresentation());
const closeButton = document.getElementById('close').addEventListener('click', () => closeFullscreen());

let slideCurrent = 0;

const keysNext = fromEvent(document, 'keyup').pipe(
  filter(event => ['ArrowDown', 'ArrowRight', ' '].includes(String(event['key']))),
  tap(event => event.preventDefault()),
);
const keysPrevious = fromEvent(document, 'keyup').pipe(
  filter(event => ['ArrowUp', 'ArrowLeft'].includes(String(event['key']))),
  tap(event => event.preventDefault()),
);
const clicks = fromEvent(document, 'click');
let keysNext$;
let keysPrevious$;
let clicks$;

const fullscreen = fromEvent(document, 'fullscreenchange').pipe(
  map(() => !!document.fullscreenElement),
  tap(() => presentation.classList.toggle('-is-active')),
  filter(isActive => !isActive),
  tap(() => closePresentation())
).subscribe(yesorno => console.log('fullscreen', yesorno));

const editor = new Quill('#editor', {
  theme: 'bubble'
});


function openPresentation() {

  // Get editor content
  const editorContent = document.querySelector('.ql-editor');
  if (!editorContent) {
    return alert('No content found');
  }

  // Create slides based on horizontal lines
  const slides = [[]];
  for (let item of editorContent.children) {
    if (item.outerHTML === '<p>---</p>' || item.outerHTML === '<p>-</p>') {
      slides.push([]);
    } else {
      slides[slides.length - 1].push(item.cloneNode(true));
    }
  }

  // Build slides DOM
  const presentationContent = document.querySelector('.presentation__content');
  for (let slideContent of slides) {
    const slide = document.createElement('div');
    slide.classList.add('presentation__slide');

    for (let item of slideContent) {
      slide.appendChild(item);
    }

    presentationContent.appendChild(slide);
  }

  // Debug and open fullscreen
  console.log('content', editorContent.innerHTML, editorContent.children, slides);
  openFullscreen();

  // Reset slide
  setTimeout(() => {
    slideCurrent = 0;
    slideMove(false);
  }, 100);

  // Create event listeners
  setTimeout(() => {
    slideMove();
    keysNext$ = keysNext.subscribe(() => slideNext());
    keysPrevious$ = keysPrevious.subscribe(() => slidePrevious());
    clicks$ = clicks.subscribe(() => slideNext());
  }, 0);
}

function closePresentation() {
  // Empty presentation slides
  presentationContent.innerHTML = '';

  // Destroy event listeners for changing slides
  keysNext$.unsubscribe();
  keysPrevious$.unsubscribe();
  clicks$.unsubscribe();

  // Reset slide
  slideCurrent = 0;
}

function slideNext() {
  if (presentationContent.children) {
    if ((slideCurrent + 1) < presentationContent.children.length) {
      slideCurrent++;
      slideMove();
    }
  }
}

function slidePrevious() {
  if (presentationContent.children) {
    if (slideCurrent > 0) {
      slideCurrent--;
      slideMove();
    }
  }
}

function slideMove(animation = true) {
  const targetSlide = presentationContent.children[slideCurrent];
  console.log('slideMove', slideCurrent, targetSlide);
  targetSlide.scrollIntoView({
    behavior: animation ? 'smooth' : 'auto',
    block: 'center',
    inline: 'nearest',
  });
}

function openFullscreen() {
  if (presentation.requestFullscreen) {
    presentation.requestFullscreen();
  // @ts-ignore
  } else if (presentation.webkitRequestFullscreen) { /* Safari */
    // @ts-ignore
    presentation.webkitRequestFullscreen();
  // @ts-ignore
  } else if (presentation.msRequestFullscreen) { /* IE11 */
    // @ts-ignore
    presentation.msRequestFullscreen();
  }
}

function closeFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  // @ts-ignore
  } else if (document.webkitExitFullscreen) { /* Safari */
    // @ts-ignore
    document.webkitExitFullscreen();
  // @ts-ignore
  } else if (document.msExitFullscreen) { /* IE11 */
    // @ts-ignore
    document.msExitFullscreen();
  }
}
